import React, { Component, useState } from "react";
import { withAdminLayout } from "../../layout";
import { withAppContext } from "../../components/withAppContext";
import { adminApiUserQuota } from "../../util/adminApi";
import { FormattedMessage } from "react-intl";
import { Input } from "../../components/Form/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const data = {
    quota: [],
    DCNames: [],
    DCFieldNames: [],
};

class userQuota extends Component {
    constructor(props) {
        super(props);

        //get the user as param from the url
        const urlParams = new URLSearchParams(props.location.search);
        const userParam = urlParams.get("user");

        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        const today = new Date();
        const thirtyDaysAgo = new Date(
            today.getTime() - 30 * 24 * 60 * 60 * 1000,
        );
        const twelveMonthsAgo = new Date(
            today.getTime() - 12 * 30 * 24 * 60 * 60 * 1000,
        );
        const febFirst2023 = new Date(2023, 1, 1);

        this.state = {
            isLoading: false,
            userName: null,
            selectedOption: "month",
            startDate:
                twelveMonthsAgo > febFirst2023 ? twelveMonthsAgo : febFirst2023,
            endDate: null,
            quotaList: {
                data: {
                    quota: data.quota.reverse(),
                    DCNames: [],
                    DCFieldNames: [],
                },
            },
        };

        //inverted the order of the table
        this.setState((prevState) => ({
            quotaList: {
                ...prevState.quotaList,
                data: {
                    ...prevState.quotaList.data,
                    quota: prevState.quotaList.data.quota.reverse(),
                },
            },
        }));

        //set the username input the username from urlparam
        if (userParam) {
            this.state.userName = userParam;
            this.fetchUserQuota(null);
        }
    }

    //fomating the numbers e.x 1000 -> 1.000
    formatAmount = (object) => {
        let format = new Intl.NumberFormat("de", { maximumFractionDigits: 3 });
        return format.format(object);
    };

    //if the start date > end date ,makes the end date null
    handleStartChange = (date) => {
        if (!this._isMounted) return;
        this.setState({ startDate: date });
        if (date > this.state.endDate) {
            this.setState({ endDate: null });
        }
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleEndChange(date) {
        this.setState({
            endDate: date,
        });
    }

    /**
     * @param {import('react').ChangeEvent<HTMLInputElement>} event
     */
    handleUpdate = (event) => {
        switch (event.target.name) {
            default:
                this.setState({
                    [event.target.name]: event.target.value,
                });
        }
    };

    //handle the ratio buttons
    handleOptionChange = (event) => {
        const today = new Date();
        const thirtyDaysAgo = new Date(
            today.getTime() - 30 * 24 * 60 * 60 * 1000,
        );
        const twelveMonthsAgo = new Date(
            today.getTime() - 12 * 30 * 24 * 60 * 60 * 1000,
        );
        const febFirst2023 = new Date(2023, 1, 1);
        const startDate =
            twelveMonthsAgo > febFirst2023 ? twelveMonthsAgo : febFirst2023;
        const startDay =
            thirtyDaysAgo > febFirst2023 ? thirtyDaysAgo : febFirst2023;

        this.setState({
            selectedOption: event.target.value,
            startDate:
                event.target.value === "day"
                    ? startDay
                    : event.target.value === "month"
                      ? startDate
                      : febFirst2023,
            endDate: null,
        });
    };

    fetchUserQuota = async (event) => {
        if (event) {
            event.preventDefault();
        }
        const { startDate, endDate, userName, selectedOption } = this.state;

        // Set the time of the dates to the end of the day
        if (endDate) {
            endDate.setHours(23, 59, 59, 999);
        }

        if (startDate) {
            startDate.setHours(0, 0, 0, 0);
        }

        const timezoneOffset = moment().utcOffset() * 60 * 1000;

        // Adjust the start and end dates based on timezone offset
        const adjustedStartDate = startDate
            ? moment(startDate.getTime() - timezoneOffset)
                  .toISOString()
                  .slice(0, 10)
            : null;
        const adjustedEndDate = endDate
            ? moment(endDate - timezoneOffset)
                  .toISOString()
                  .slice(0, 10)
            : null;
        const sendData = {
            filter: {
                userName: userName,
                dateFrom: adjustedStartDate,
                dateTo: adjustedEndDate,
                groupYear: selectedOption === "year",
                groupMonth: selectedOption === "month",
            },
        };

        try {
            const quotaList = await adminApiUserQuota(sendData);
            quotaList.data.quota.reverse();
            const _DCFieldNames = [];
            quotaList.data.DCNames.forEach((e, i) => {
                // each DCName is in the form {quotaFromA: "Vautron"} We need the single property name
                // in order to reference the data fields later on
                _DCFieldNames.push(Object.keys(e)[0]);
            });
            quotaList.data.DCFieldNames = _DCFieldNames;
            quotaList.data.quota.forEach((e) => {
                e.total = 0;
                quotaList.data.DCFieldNames.forEach(
                    (f) => (e.total += e[f] || 0),
                );
            });
            this.setState({
                quotaList: quotaList,
            });
        } catch (error) {
            if (error.message === "404") {
                alert("User not found");
                this.setState({ quotaList: [] });
            } else {
                // Handle other errors
            }
        }
    };

    render() {
        const { isLoading, type, quotaState, active } = this.state;
        return (
            <>
                <h1 className="admin">
                    <FormattedMessage id="dashboard.Admin User" />
                </h1>
                <form
                    method="post"
                    onSubmit={(event) => {
                        this.fetchUserQuota(event);
                    }}
                >
                    <Input
                        label="User"
                        type="text"
                        className="form-input"
                        name="userName"
                        placeholder=""
                        value={this.state.userName}
                        handleChange={this.handleUpdate}
                    />
                    <div className="form-group">
                        <span>From Date</span>
                        <DatePicker
                            className="form-input"
                            selected={this.state.startDate}
                            onChange={this.handleStartChange}
                            name="startDate"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    <div className="form-group">
                        <span>To Date</span>
                        <DatePicker
                            className="form-input"
                            selected={this.state.endDate}
                            onChange={this.handleEndChange}
                            name="endDate"
                            dateFormat="dd/MM/yyyy"
                        />
                    </div>
                    {/* radio buttons */}
                    <div className="form-group" style={{ display: "flex" }}>
                        <div className="form-check">
                            <label style={{ paddingRight: "10px" }}>
                                <input
                                    type="radio"
                                    name="react-tips"
                                    value="year"
                                    checked={
                                        this.state.selectedOption === "year"
                                    }
                                    onChange={this.handleOptionChange}
                                    className="form-check-input"
                                />
                                Year
                            </label>
                        </div>

                        <div className="form-check">
                            <label style={{ paddingRight: "10px" }}>
                                <input
                                    type="radio"
                                    name="react-tips"
                                    value="month"
                                    checked={
                                        this.state.selectedOption === "month"
                                    }
                                    onChange={this.handleOptionChange}
                                    className="form-check-input"
                                />
                                Month
                            </label>
                        </div>

                        <div className="form-check">
                            <label style={{ paddingRight: "10px" }}>
                                <input
                                    type="radio"
                                    name="react-tips"
                                    value="day"
                                    checked={
                                        this.state.selectedOption === "day"
                                    }
                                    onChange={this.handleOptionChange}
                                    className="form-check-input"
                                />
                                Day
                            </label>
                        </div>
                    </div>
                    <button
                        className={`btn ${isLoading && "loading"}`}
                        disabled={isLoading}
                        type="submit"
                    >
                        Suchen{" "}
                        {/*<FormattedMessage id="loginregister.register" />*/}
                    </button>
                </form>
                <br></br>
                <table className="table table-striped ">
                    <thead>
                        <tr>
                            {this.state.quotaList &&
                                this.state.quotaList.data &&
                                this.state.quotaList.data.quota.some(
                                    (item) => item.year !== undefined,
                                ) && <th>Year</th>}
                            {this.state.quotaList &&
                                this.state.quotaList.data &&
                                this.state.quotaList.data.quota.some(
                                    (item) => item.month !== undefined,
                                ) && <th>Month</th>}
                            {this.state.quotaList &&
                                this.state.quotaList.data &&
                                this.state.quotaList.data.quota.some(
                                    (item) => item.dayOfMonth !== undefined,
                                ) && <th>Day of Month</th>}
                            {this.state.quotaList &&
                                this.state.quotaList.data && <th>Total</th>}
                            {this.state.quotaList &&
                                this.state.quotaList.data &&
                                this.state.quotaList.data.DCNames.map(
                                    (item, index) => (
                                        <th>
                                            {
                                                item[
                                                    this.state.quotaList.data
                                                        .DCFieldNames[index]
                                                ]
                                            }
                                        </th>
                                    ),
                                )}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.quotaList &&
                            this.state.quotaList.data &&
                            this.state.quotaList.data.quota &&
                            this.state.quotaList.data.quota.map(
                                (item, index) => (
                                    <tr key={index}>
                                        {item.year && <td>{item.year}</td>}
                                        {item.month && <td>{item.month}</td>}
                                        {item.dayOfMonth && (
                                            <td>{item.dayOfMonth}</td>
                                        )}
                                        <td>{this.formatAmount(item.total)}</td>
                                        {this.state.quotaList.data.DCFieldNames.map(
                                            (e) => {
                                                return (
                                                    <td>
                                                        {this.formatAmount(
                                                            item[e],
                                                        )}{" "}
                                                        (
                                                        {item.total !== 0
                                                            ? (
                                                                  (item[e] /
                                                                      item.total) *
                                                                  100
                                                              )
                                                                  .toFixed(1)
                                                                  .replace(
                                                                      ".",
                                                                      ",",
                                                                  ) + "%"
                                                            : "0%"}
                                                        )
                                                    </td>
                                                );
                                            },
                                        )}
                                    </tr>
                                ),
                            )}
                    </tbody>
                </table>
            </>
        );
    }
}

const customProps = {
    withAuth: true,
};

export default withAdminLayout(customProps)(withAppContext(userQuota));
